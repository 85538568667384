export default {
  en: {
    profile: 'My Profile',
    logout: 'Logout',
    help: 'Help center',
    menu: {
      '2fa': '2FA security',
      virtualBackground: 'Virtual background',
      notifications: 'Notifications',
      settings: 'Settings',
    },
    multiFactorAuth: {
      title: 'Two factor authentication',
      '2fa': 'Two factor authentication',
      '2faDescription': 'adds an extra layer of security to your account, this allows you to prevent access to a third party if your password is compromised or stolen, only you will be able to access it.',
      '2faApps': 'We recommend you to use apps like following:',
      codeMessage: 'If you cannot scan this QR code at this time, use this secret key in your application',
      enterPin: 'Enter the code of 6 digits',
      expiredPin: 'The code is expired, try again.',
      backupCodes: 'Backup codes',
      backupCodesDescription: 'If you lose access to the app, you can use one of the following 10 codes, and each backup code is one-time use.',
      backupCodesAlert: 'Save these codes, you will not be able to access them again later.',
      downloadBackupCodes: 'Download backup codes',
      printBackupCodes: 'Print backup codes',
      rememberHowTo: 'Remember that if you lose access to the rotating keys you can use the backup keys. In case you have lost both, send us a ticket or contact us at',
      disable: {
        title: 'Follow the instructions to disable 2FA',
        warning: 'If you do this you will lose the extra security for your account.',
        alert: 'Remember to remove this account from your app to avoid duplicate accounts if you turn 2FA back on.',
        enterPinOrBackup: 'Enter a PIN or backup code',
        placeholderPin: 'PIN or backup code'
      },
      steps: {
        '1': 'Scan the QR code with the 2FA app',
        '2': 'Save the following backup codes',
      },
    },
    virtualBackground: {
      title: '',
      description: 'With this function you can add a virtual background preventing your surroundings from being visible.',
      blurred: 'Blurred',
      background: 'Background replacement',
      gallery: 'These pictures were uploaded by you admin',
      changesSaved: 'Changes saved successfully',
    },
    settings: {
      title: 'Settings',
      ringtone: {
        select: 'Ringtone',
        play: 'Play ringtone',
        playing: 'Playing ringtone',
        saveSuccess: 'Ringtone saved successfully',
        save: 'Save changes',
        classic: 'Classic',
        'clear-ring': 'Clear Ring',
        'future-sonar': 'Future Sonar',
        'marimba-2': 'Marimba 2',
        'noisy': 'Noisy',
        'dodo': 'Dodo',
        'happy-bells': 'Happy Bells',
        'marimba': 'Marimba',
        'smooth-piano': 'Smooth Piano',
        'electric-ring': 'Electric Ring',
        'hey': 'Hey',
        'modern': 'Modern'
      }
    }
  },
  es: {
    profile: 'Mi perfil',
    logout: 'Salir',
    help: 'Centro de ayuda',
    menu: {
      '2fa': 'Seguridad 2FA',
      virtualBackground: 'Fondo virtual',
      notifications: 'Notificaciones',
      settings: 'Ajustes',
    },
    multiFactorAuth: {
      title: 'Autenticación de dos factores',
      '2fa': 'Autentificación de doble factor',
      '2faDescription': 'añade una capa extra de seguridad a tu cuenta, esto permite impedir el acceso a un tercero si tu contraseña se ve comprometida o robada, solo tu podrás acceder.',
      '2faApps': 'Te recomendamos utilizar aplicaciones como las siguientes:',
      codeMessage: 'Si en este momento no puedes escanear este código QR utiliza esta clave secreta en tu aplicación',
      enterPin: 'Ingresa el código de 6 dígitos',
      expiredPin: 'El código está expirado, inténtalo de nuevo.',
      backupCodes: 'Códigos de respaldo',
      backupCodesDescription: 'Si pierdes acceso a la aplicación, podrás utilizar alguno de los siguientes 10 códigos, y cada código de respaldo es de un solo uso.',
      backupCodesAlert: 'Guarda estos códigos, no podrás volver a acceder a ellos posteriormente.',
      downloadBackupCodes: 'Descargar códigos de respaldo',
      printBackupCodes: 'Imprimir códigos de respaldo',
      rememberHowTo: 'Recuerda que si pierdes acceso a las claves rotativas podrás usar las claves de respaldo. En caso que hayas perdido ambas, envíanos un ticket o contáctanos a',
      disable: {
        title: 'Siga las instrucciones para desactivar 2FA',
        warning: 'Si haces esto perderás la seguridad extra para tu cuenta.',
        alert: 'Recuerda eliminar esta cuenta de tu aplicación para evitar cuentas duplicadas si vuelves a activar 2FA.',
        enterPinOrBackup: 'Ingresa una clave 2FA o código de respaldo.',
        placeholderPin: 'Clave 2FA o de código de respaldo'
      },
      steps: {
        '1': 'Escanea este código QR con tu aplicación',
        '2': 'Guarda los códigos de respaldo',
      },
    },
    virtualBackground: {
      title: '',
      description: 'Con esta función podrás añadir un fondo virtual evitando que tu entorno sea visible.',
      blurred: 'Difuminado',
      background: 'Fondo virtual',
      gallery: 'Estas imágenes fueron subidas por tu administrador.',
      changesSaved: 'Cambios guardados correctamente',
    },
    settings: {
      title: 'Ajustes',
      ringtone: {
        select: 'Tono de llamada',
        play: 'Reproducir tono de llamada',
        playing: 'Reproduciendo tono de llamada',
        saveSuccess: 'Tono de llamada guardado correctamente',
        save: 'Guardar cambios',
        classic: 'Clásico',
        'clear-ring': 'Tono claro',
        'future-sonar': 'Sonar del futuro',
        'marimba-2': 'Marimba 2',
        'noisy': 'Ruidoso',
        'dodo': 'Dodo',
        'happy-bells': 'Campanas felices',
        'marimba': 'Marimba',
        'smooth-piano': 'Piano suave',
        'electric-ring': 'Tono eléctrico',
        'hey': 'Hey',
        'modern': 'Moderno'
      }
    }
  },
  fr: {
    profile: 'Mon Profil',
    logout: 'Déconnexion',
    help: 'Centre d\'aide',
    menu: {
      '2fa': 'Sécurité 2FA',
      virtualBackground: 'Arrière-plan virtuel',
      notifications: 'Notifications',
      settings: 'Paramètres',
    },
    multiFactorAuth: {
      title: 'Authentification à deux facteurs',
      '2fa': 'Authentification à deux facteurs',
      '2faDescription': 'ajoute une couche supplémentaire de sécurité à votre compte, cela vous permet d\'empêcher l\'accès à un tiers si votre mot de passe est compromis ou volé, seul vous pourrez y accéder.',
      '2faApps': 'Nous vous recommandons d\'utiliser des applications comme les suivantes :',
      codeMessage: 'Si vous ne pouvez pas scanner ce code QR pour l\'instant, utilisez cette clé secrète dans votre application',
      enterPin: 'Entrez le code de 6 chiffres',
      expiredPin: 'Le code a expiré, réessayez.',
      backupCodes: 'Codes de secours',
      backupCodesDescription: 'Si vous perdez l\'accès à l\'application, vous pouvez utiliser l\'un des 10 codes suivants, chaque code de secours est à usage unique.',
      backupCodesAlert: 'Sauvegardez ces codes, vous ne pourrez pas y accéder à nouveau plus tard.',
      downloadBackupCodes: 'Télécharger les codes de secours',
      printBackupCodes: 'Imprimer les codes de secours',
      rememberHowTo: 'N\'oubliez pas que si vous perdez l\'accès aux clés rotatives, vous pouvez utiliser les clés de secours. En cas de perte des deux, envoyez-nous un ticket ou contactez-nous à',
      disable: {
        title: 'Suivez les instructions pour désactiver la 2FA',
        warning: 'Si vous faites cela, vous perdrez la sécurité supplémentaire pour votre compte.',
        alert: 'N\'oubliez pas de retirer ce compte de votre application pour éviter les comptes en double si vous réactivez la 2FA.',
        enterPinOrBackup: 'Entrez un code PIN ou un code de secours',
        placeholderPin: 'PIN ou code de secours'
      },
      steps: {
        '1': 'Scannez le code QR avec l\'application 2FA',
        '2': 'Sauvegardez les codes de secours suivants',
      },
    },
    virtualBackground: {
      title: '',
      description: 'Avec cette fonction, vous pouvez ajouter un arrière-plan virtuel empêchant la visibilité de votre environnement.',
      blurred: 'Flou',
      background: 'Remplacement de l\'arrière-plan',
      gallery: 'Ces images ont été téléchargées par vous, administrateur',
      changesSaved: 'Modifications enregistrées avec succès',
    },
    settings: {
      title: 'Paramètres',
      ringtone: {
        select: 'Sonnerie',
        play: 'Lire la sonnerie',
        playing: 'Lecture de la sonnerie',
        saveSuccess: 'Sonnerie enregistrée avec succès',
        save: 'Enregistrer les modifications',
        classic: 'Classique',
        'clear-ring': 'Sonnerie claire',
        'future-sonar': 'Sonar du futur',
        'marimba-2': 'Marimba 2',
        'noisy': 'Bruit',
        'dodo': 'Dodo',
        'happy-bells': 'Cloches joyeuses',
        'marimba': 'Marimba',
        'smooth-piano': 'Piano doux',
        'electric-ring': 'Sonnerie électrique',
        'hey': 'Hey',
        'modern': 'Moderne'
      }
    }
  }
};
