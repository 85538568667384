<template>
  <div class="cn-container">
    <router-view />
    <a-modal
      :title="$t('clickModal.title')"
      v-model="modalOpen"
      centered
      :ok-text="$t('connect')"
      :cancel-text="$t('cancel')"
      @ok="connectConsole"
      :closable="false"
      :mask-closable="false"
      :cancel-button-props="{ props: { disabled: true } }"
      :ok-button-props="{ props: { disabled: !haveMicrophone } }"
    >
      <a-alert
        v-show="!errorCaptureMessage"
        :message="$t('general.checkEnvironment')"
        type="info"
        show-icon
        style="margin-bottom: 10px;"
      />
      <div style="width: 100%; display: flex; justify-content: center;">
        <video
          autoplay
          playsinline
          muted
          ref="preview"
          id="preview"
          v-show="!errorCaptureMessage"
        />
      </div>
      <div class="camera-permissions">
        <div
          class="device"
          :class="{ error: !haveMicrophone }"
        >
          {{ $t('general.mic') }}
        </div>
        <div
          class="device"
          :class="{ error: !haveCamera }"
        >
          {{ $t('general.cam') }}
        </div>
      </div>
      <a-alert
        v-show="errorCaptureMessage"
        :message="$t('general.mediaUserError')"
        type="error"
        show-icon
        style="margin: 1rem 0 0.5rem 0;"
      />
      <div
        class="capture-error error"
        v-show="errorCaptureMessage"
      >
        {{ errorCaptureMessage }}
      </div>
      <a-alert
        v-show="errorCaptureMessage"
        :message="$t('general.tryAgain')"
        type="info"
        show-icon
        style="margin: 1rem 0 0.5rem 0;"
      />
      <a-button
        type="primary"
        style="margin-top: 1rem;"
        @click="reload"
        v-show="errorCaptureMessage"
      >
        {{ $t('tryAgain') }}
      </a-button>
      <p
        style="margin-top: 1rem;"
        v-show="!errorCaptureMessage"
      >
        <strong>{{ $t('general.clickConnect') }}</strong>
      </p>
    </a-modal>
    <div
      class="calendar-sidebar"
      v-show="calendarEnabled"
      ref="calendarContainer"
    >
      <div class="calendar-sub-container">
        <div
          class="calendar-tag"
          @click="toggleCalendar"
        >
          <a-icon
            type="calendar"
            theme="filled"
          />
        </div>
        <div
          id="calendar"
          class="calendar-container"
        />
        <a-drawer
          :visible="openAppointmentDrawer"
          :destroy-on-close="true"
          :mask="false"
          :width="drawerWidth"
          :closable="false"
          placement="right"
        >
          <appointment-view
            @join="toggleCalendarAndDrawer"
            @reschedule="rescheduleAppointment"
          />
        </a-drawer>
      </div>
    </div>
    <a-drawer
      :visible="createAppointment"
      :destroy-on-close="true"
      :mask="false"
      :width="drawerWidth"
      :closable="false"
      placement="right"
      id="appointment-create"
    >
      <appointment-create
        ref="create-calendar"
        @close="closeCreateAppointment"
        @rescheduled="rescheduledAndClose"
      />
    </a-drawer>
    <a-button
      class="schedule-button"
      @click="createAppointment = true"
      v-show="!createAppointment && $authorization.getTokens('accessToken') && calendarEnabled"
    >
      Agendar
    </a-button>
    <browser-compatibility />
  </div>
</template>


<script>
import Sentry from '@/plugins/sentry';
import Pendo from '@/plugins/pendo';
import AppointmentView from '@/components/appointment-view.vue';
import AppointmentCreate from '@/components/appointment-create.vue';
import BrowserCompatibility from '@/components/version-banner.vue';
import NotificationAPI from '@/plugins/notification';
import Calendar from 'tui-calendar';

export default {
  components: {
    AppointmentView,
    AppointmentCreate,
    BrowserCompatibility,
  },
  data: () => ({
    modalOpen: false,
    sidebarOpen: false,
    drawerWidth: 400,
    streamCapture: new MediaStream(),
    errorCaptureMessage: '',
    canConnect: false,
    createAppointment: false,
  }),
  computed: {
    calendarEnabled() {
      return this.$store.getters['global/settings'].calendarEnabled;
    },
    appointments() {
      return this.$store.getters['calendar/appointments'];
    },
    openAppointmentDrawer: {
      get() {
        return this.$store.getters['calendar/openAppointmentDrawer'];
      },
      set(value) {
        this.$store.commit('calendar/openAppointmentDrawer', value);
      },
    },
    colorsAppointments() {
      return {
        blocked: 'rgb(128, 128, 128',
        canceled: 'rgb(255, 0, 0',
        rescheduled: 'rgb(255, 165, 0',
        pending: 'rgb(0, 0, 255',
        attended: 'rgb(0, 0, 255',
        external: 'rgb(79, 79, 79',
      };
    },
    haveMicrophone() {
      return this.streamCapture.getAudioTracks().length > 0;
    },
    haveCamera() {
      return this.streamCapture.getVideoTracks().length > 0;
    },
  },
  watch: {
    appointments(value) {
      const locale = (date) => new Intl.DateTimeFormat(window.navigator.language, { hour: '2-digit', minute: '2-digit' }).format(new Date(date));
      const appointments = value.map(({
        startAt, endAt, id, service, type, status, title: externalTitle
      }) => {
        const now = new Date();
        const title = externalTitle || (type === 'blocked' ? this.$t('words.blocked') : service?.title);
        const keyColor = ['blocked', 'external'].includes(type) ? type : status;
        const opacity = new Date(endAt) < now ? 0.3 : 1;
        const outputColor = `${this.colorsAppointments[keyColor]},${opacity})`;
        return {
          id,
          calendarId: '1',
          title: `${locale(startAt)} - ${locale(endAt)} / ${title}`,
          category: 'time',
          dueDateClass: 'on-past',
          start: startAt,
          end: endAt,
          color: '#ffffff',
          bgColor: outputColor,
          dragBgColor: outputColor,
          borderColor: outputColor,
          isReadOnly: ['canceled', 'rescheduled'].includes(status) || new Date(endAt) < now,
        };
      });
      this.$calendar.clear();
      this.$calendar.createSchedules(appointments);
    },
  },
  async mounted() {
    document.addEventListener('inbound:play', () => (!(this.$store.getters['call/active'] || this.$store.getters['calendar/onMeeting'])) && this.$audioMixer?.play('inbound'));
    document.addEventListener('inbound:pause', () => this.$audioMixer?.pause('inbound'));
    document.addEventListener('offline:play', () => (!(this.$store.getters['call/active'] || this.$store.getters['calendar/onMeeting'])) && this.$audioMixer?.play('offline'));
    document.addEventListener('offline:pause', () => this.$audioMixer?.pause('offline'));
    document.addEventListener('connected', () => {
      this.$notification.close('connecting');
      this.$notification.close('reconnecting');
    });
    document.addEventListener('connecting', () => {
      this.$notification.close('connecting');
      this.$notification.open({
        key: 'connecting',
        message: this.$t('connecting'),
        onClick: () => {},
        onClose: () => {},
        closeIcon: () => '',
        class: 'no-closeable',
        placement: 'bottom',
        btn: h => {
          return h(
            'a-icon',
            {
              props: {
                type: 'loading',
                spin: true,
              },
            });
        }
      });
    });
    document.addEventListener('reconnecting', (event) => {
      const [code, attempt] = event.detail;
      this.$notification.close('reconnecting');
      this.$notification.open({
        key: 'reconnecting',
        message: 'Reconectando',
        description:
          `Estamos intentando reconectar automáticamente, intento ${attempt}. Codigo: ${code}`,
        duration: 0,
        onClick: () => {},
        onClose: () => {},
        class: 'no-closeable',
        placement: 'bottom',
        btn: h => {
          return h(
            'a-icon',
            {
              props: {
                type: 'loading',
                spin: true,
              },
            });
        }
      });
    });
    document.addEventListener('http-error', event => {
      const { code, message } = event.detail;
      if (code !== 401) this.$Notify.error({ showClose: false, title: this.$t('status.offline'), message: `Code (${code}): ${message}` });
    });
    document.addEventListener('hangup:error', event => {
       const { message, errors = [] } = event.detail;
       const [errorKey] = errors;
       this.$Notify.warning({ title: this.$t('validation.title'), message: errorKey ? this.$t(`validation.${errorKey}`) : message });
       this.$Notify.warning({ title: this.$t('validation.title'), message: errorKey ? this.$t(`validation.${errorKey}`) : message });
    });
    document.addEventListener('session:duplicated', () => {
      this.$notification.error({ message: this.$t('notifications.duplicatedSessionTitle'), description: this.$t('notifications.duplicatedSessionDescription') })
    });
    document.addEventListener('appointments:find', () => {
      this.$store.getters['global/socket']?.emit('appointments:find', { date: new Date(this.$calendar.getDate()._date).toISOString() });
    });
    this.notifyNotification();
    this.$shortcuts();
    this.createCalendarView();
    const redirect = () => !(['/login', '/password/reset', '/password/forget'].includes(window.location.pathname));

    this.$authorization.on('expired', (token) => {
      if (token === 'refreshToken') {
        this.$store.commit('global/refreshTokenExpired', true);
        this.$authorization.logout();
      }
      if (redirect() && token === 'refreshToken') this.forceLogoutAndDisconnect();
    });

    this.$authorization.on('logout', () => this.forceLogoutAndDisconnect());

    this.$authorization.on('empty', () => (redirect()) && this.$router.push({ name: 'login' }));

    this.$authorization.on('error', (error) => {
      this.$store.commit('global/refreshTokenExpired', true);
      this.$authorization.logout();
      Sentry.withScope(scope => {
        scope.setTag('@videsk/jwt-webauth', this.$authorization.version);
        scope.setLevel('error');
        Sentry.captureException(error);
      });
    });

    this.$authorization.on('ready', async () => {
      this.modalOpen = true;
      this.setAuthorizationHeader();
      const result = await this.getCamera();
      if (result instanceof Error) return this.errorCaptureMessage = result.message;
      this.canConnect = true;
      if (window.location.pathname.includes('/login')) await this.$router.push({ name: 'home' });
      await this.getUserMetadata();
      this.setPendo();
      this.mixpanel();
      document.dispatchEvent(new CustomEvent('create-calendar'));
    });
    await this.$authorization.set();

    this.$store.commit('global/refreshTokenExpired', false);
    window.addEventListener('offline', () => {
      this.$Notify.warning({ message: this.$t('general.browserOffline'), duration: 0 });
    });
  },
  methods: {
    reload() {
      window.location.reload();
    },
    async getCamera() {
      const webrtc = document.createElement('videsk-webrtc');
      const camera = await webrtc.camera().catch(e => e);
      if (camera instanceof Error) {
        this.errorCaptureMessage = camera.message;
        this.$Notify.error({ title: this.$t('general.mediaUserError'), message: camera.message });
        return camera;
      }
      this.$store.commit('global/globalCameraStream', camera);
      this.streamCapture = camera;
      this.$refs.preview.srcObject = camera;
      webrtc.remove();
      return camera;
    },
    async connectConsole() {
      try {
        await this.$store.dispatch('global/connect');
        this.modalOpen = false;
        this.$audioMixer = this.$store.getters['global/mixer'];
        const { ringtone } = this.$store.getters['global/userSettings'] || {};
        await Promise.all([
          this.$audioMixer.addChannel('inbound', ringtone || 'https://cdn.videsk.io/ringtones/inbound.mp3'),
          this.$audioMixer.addChannel('offline', 'https://cdn.videsk.io/ringtones/offline.mp3'),
        ]);
      } catch (error) {
        console.error(error);
      }
    },
    async getUserMetadata() {
      const decode = JWT => JSON.parse(atob(JWT.split('.')[1]));
      const accessToken = this.$authorization.getTokens('accessToken');
      const { org, aud } = decode(accessToken);
      this.$store.commit('global/account', org);
      this.$store.commit('global/id', aud);
      await this.$store.dispatch('global/getUserData');
      await this.$store.dispatch('global/loadUserSettings');
      if (process.env.NODE_ENV !== 'production') return;
      Sentry.setUser({
        id: this.$store.getters['global/id'],
        account: this.$store.getters['global/account'],
        username: this.$store.getters['global/email'],
      });
      this.$rocket.identify(this.$store.getters['global/id'], {
        email: this.$store.getters['global/email'],
        account: this.$store.getters['global/account'],
      });
      this.$rocket.getSessionURL(sessionURL => {
        Sentry.getCurrentScope().setExtra('sessionURL', sessionURL);
      });
    },
    setAuthorizationHeader() {
      this.axios.defaults.headers.common.Authorization = `Bearer ${this.$authorization.getTokens('accessToken')}`;
    },
    wait(timeout = 1000) {
      return new Promise(resolve => setTimeout(resolve, timeout));
    },
    mixpanel() {
      const {
        email, account, firstname, lastname
      } = this.$store.getters['global/metaData'] || {};
      this.$mixpanel.identify(this.$store.getters['global/id']);
      this.$mixpanel.set_group('company', account.name);
      this.$mixpanel.people.set({
        $first_name: firstname,
        $last_name: lastname,
        $email: email,
        Business: account.name,
      });
    },
    setPendo() {
      const {
        id,
        firstname,
        lastname,
        email,
        account,
      } = this.$store.getters['global/metaData'];
      Pendo(
        {
          id,
          lastname,
          firstname,
          email,
        },
        {
          id: account._id,
          name: account.name,
        },
      );
    },
    getCurrentDatetime(date) {
      const formattedDate = new Date(`${date} 00:00:00`);
      return new Intl.DateTimeFormat(window.navigator.language, { day: 'numeric', month: 'long' }).format(formattedDate);
    },
    getCurrentWeekDayName(date) {
      const formattedDate = new Date(`${date} 00:00:00`);
      const locale = new Intl.DateTimeFormat(window.navigator.language, { weekday: 'long' }).format(formattedDate);
      return locale.charAt(0).toUpperCase() + locale.slice(1);
    },
    arrowRight() {
      return '<svg viewBox="0 0 1024 1024" data-icon="caret-right" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M715.8 493.5L335 165.1c-14.2-12.2-35-1.2-35 18.5v656.8c0 19.7 20.8 30.7 35 18.5l380.8-328.4c10.9-9.4 10.9-27.6 0-37z"></path></svg>';
    },
    arrowLeft() {
      return '<svg viewBox="0 0 1024 1024" data-icon="caret-left" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M689 165.1L308.2 493.5c-10.9 9.4-10.9 27.5 0 37L689 858.9c14.2 12.2 35 1.2 35-18.5V183.6c0-19.7-20.8-30.7-35-18.5z"></path></svg>';
    },
    today() {
      this.$calendar.today();
    },
    toggleCalendarAndDrawer() {
      this.openAppointmentDrawer = false;
      this.toggleCalendar(false);
    },
    createCalendarView() {
      this.$calendar = new Calendar('#calendar', {
        defaultView: 'day',
        useCreationPopup: false,
        useFormPopup: false,
        isReadOnly: true,
        useDetailPopup: false,
        taskView: false,
        scheduleView: ['time'],
        template: {
          weekDayname: (model) => {
            const weekday = this.getCurrentWeekDayName(model.renderDate);
            const month = this.getCurrentDatetime(model.renderDate);
            setTimeout(() => this.addEventListener(), 50);
            return `
                    <div class="calendar-header">
                        <div class="calendar-header-arrow">
                            <div id="change-day-previous" class="calendar-header-arrow-button prev">${this.arrowLeft()}</div>
                        </div>
                        <div class="calendar-header-data">
                            <div class="calendar-header-weekday">${weekday}</div>
                            <div class="calendar-header-daytime">${month}</div>
                        </div>
                        <div class="calendar-header-arrow">
                            <div id="change-day-next" class="calendar-header-arrow-button next">${this.arrowRight()}</div>
                        </div>
                    </div>
                    <div class="calendar-users-header">
                        <a-select v-model="users"></a-select>
                    </div>
            `;
          },
        },
        MonthOptions: {
          startDayOfWeek: 1,
        },
        WeekOptions: {
          startDayOfWeek: 1,
        }
      });
      window.calendar = this.$calendar;
      this.$calendar.on('clickSchedule', (event) => {
        const { id } = event.schedule;
        this.$store.dispatch('calendar/getAppointment', id);
      });
      window.addEventListener('resize', () => {
        this.$calendar.render();
        if (!this.$refs.calendarContainer) return;
        this.drawerWidth = this.$refs.calendarContainer.clientWidth;
      });
      this.$calendar.on('beforeCreateSchedule', event => {});
      this.$calendar.on('beforeUpdateSchedule', event => {
        const { start, end, schedule } = event;
        const data = { id: schedule.id };
        if (start) data.startAt = start._date.toISOString();
        if (end) data.endAt = end._date.toISOString();
        this.$store.dispatch('calendar/patchAppointment', data);
      });
      setTimeout(() => this.$calendar.scrollToNow(), 4000);
    },
    toggleCalendar(value) {
      this.sidebarOpen = typeof value === 'boolean' ? value : !this.sidebarOpen;
      const sidebar = document.querySelector('.calendar-sidebar');
      sidebar.classList.toggle('open', this.sidebarOpen);
      if (this.sidebarOpen) document.dispatchEvent(new CustomEvent('appointments:find'));
    },
    getAppointments(date) {
      document.dispatchEvent(new CustomEvent('appointments:find'));
    },
    nextDate() {
      window.calendar = this.$calendar;
      this.$calendar.next();
      const date = new Date(this.$calendar.getDate()._date);
      this.$store.commit('calendar/currentDate', date);
      this.getAppointments(date);
    },
    previousDate() {
      this.$calendar.prev();
      const date = new Date(this.$calendar.getDate()._date);
      this.$store.commit('calendar/currentDate', date);
      this.getAppointments(date);
    },
    async addEventListener() {
      document.querySelector('#change-day-previous').onclick = () => this.previousDate();
      document.querySelector('#change-day-next').onclick = () => this.nextDate();
    },
    forceLogoutAndDisconnect() {
      if (this.$store.getters['call/active'] || this.$store.getters['calendar/onMeeting']) return this.$notification.warning({
        message: this.$t('notifications.expiredDuringCall'),
        duration: 0,
        btn: h => {
          return h(
            'a-button',
            {
              props: {
                type: 'primary',
                size: 'small',
              },
              on: {
                click: () => window.location.reload(),
              },
            },
            this.$t('refresh'),
          );
        }
      });
      this.$store.commit('global/forcedDisconnected', true);
      this.$store.commit('global/measureNetwork', false);
      this.$router.push({ name: 'login' });
      this.$store.getters['global/socket']?.disconnect();
      this.$audioMixer?.destroy();
      clearInterval(this.$store.getters['global/interval']);
      if (window.loggedout) return window.location.reload();
      this.$Notify.warning({ message: this.$t('general.expiredSession'), duration: 0 });
      new NotificationAPI().send('Tu sesión ha expirado', 'Vuelve a iniciar sesión con tus credenciales.');
      window.loggedout = false;
    },
    notifyNotification() {
      document.addEventListener('notification:update', event => {
        const { total } = event.detail;
        const unit = this.$tc('notification', total);
        const key = `notification-${new Date().getTime()}`;
        this.$notification.info({
          message: this.$t('general.totalNotifications', { unit, total }),
          btn: h => h(
            'a-button',
            {
              props: {
                type: 'primary',
                size: 'small',
              },
              on: {
                click: () => {
                  this.$router.push({ name: 'notifications' });
                  this.$notification.close(key);
                },
              },
            },
            this.$t('viewNotification'),
          ),
          key,
        });
      });
    },
    closeCreateAppointment() {
      this.createAppointment = false;
    },
    async rescheduleAppointment(appointment) {
      this.createAppointment = true;
      await this.wait(200);
      this.$refs['create-calendar'].setReschedule(appointment);
    },
    rescheduledAndClose() {
      this.openAppointmentDrawer = false;
      this.createAppointment = false;
      this.toggleCalendar(false);
      this.getAppointments();
    }
  },
};
</script>

<style>
video#preview {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  margin: 1rem 0;
}

.capture-error {
  width: 100%;
  font-size: 1rem;
  color: red;
  font-weight: 500;
  margin: 1rem 0 0.5rem 0;
}

.capture-error.error {
  font-size: 0.8rem;
  font-family: monospace;
  margin: 0.5rem 0;
  width: 100%;
  background: #dadada;
  padding: 1rem;
  border-radius: 5px;
  color: black;
}

.calendar-sidebar {
  position: fixed;
  top: 0;
  right: -400px;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  z-index: 9999;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  transition: all 0.5s ease;
}

.calendar-sidebar.open {
  right: 0;
}

.calendar-sub-container {
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -4px rgb(0 0 0 / 10%);
  cursor: pointer;
}

.calendar-container {
  width: 100%;
  height: 100%;
}

.calendar-tag {
  padding: 15px;
  position: absolute;
  top: 0;
  left: -44px;
  border-radius: 2px 0 0 2px;
  font-weight: bold;
  background: #0d64d0;
  color: white;
  transform: translateY(10vh);
  z-index: 9;
}

.tui-full-calendar-dayname-leftmargin {
  margin: 0 !important;
}

.tui-full-calendar-dayname-layout {
  height: 100px !important;
}

.tui-full-calendar-dayname-container {
  overflow: hidden !important;
  height: 100px !important;
  margin: 0 !important;
}

.tui-full-calendar-vlayout-container {
  height: calc(100% - 100px) !important;
}

.tui-full-calendar-dayname-date-area {
  width: 100%;
  display: flex;
  flex-direction: column;
}

[data-panel-index="0"] {
  height: 100% !important;
}

.calendar-header {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-header-arrow {
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-header-data {
  width: calc(100% - 100px);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 0 10px;
}

.calendar-header-arrow-button {
  width: 30px;
  height: 30px;
  border: 1px solid #233142;
  color: #233142;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-header-arrow-button:hover {
  background: #233142;
  color: white;
}

.calendar-header-weekday {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 5px;
}


.calendar-header-daytime {
  font-size: 1rem;
  font-weight: normal;
}

.calendar-users-header {
  width: 100%;
  height: 50%;
}

@media screen and (max-width: 500px) {
  .calendar-sidebar {
    right: -100%;
    max-width: 100%;
  }

  .calendar-sidebar.open {
    right: 0;
  }

  .calendar-sidebar.open .calendar-tag {
    left: 0;
  }

  .ant-drawer-content-wrapper {
        width: 100vw !important;
    }
}

.camera-permissions {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.camera-permissions .device {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.6rem;
}

.camera-permissions .device:before {
  content: '✅';
  display: flex;
  width: 1rem;
  margin-right: 1rem;
}

.camera-permissions .device.error:before {
  content: '❌';
}

.schedule-button {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
}

.no-closeable .ant-notification-notice-close{
  display: none;
}

.ant-notification-notice-message:empty {
    display: none;
}

.ant-notification-notice-icon {
    line-height: 0;
}

.ant-notification-notice-close {
  top: 30%;
}
</style>
